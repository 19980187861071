.card {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    transition: all 0.3s ease;
}

    .card img {
        max-width: 100%;
        height: auto;
    }

    .card.selected {
        border: 3px solid #8B4513; /* Dark brown border for selected card */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow effect */
        transform: scale(1.05); /* Slightly enlarge the selected card */
    }

